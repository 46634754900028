<template>
  <div v-if="isPermission && isEdit">
    <div class="row" style="margin: 5px">
      <div class="col-md-10 col-9">
        <h2 class="font-weight-normal">
          {{ $t('createCheckStock') }}
        </h2>
      </div>
    </div>
    <CCol md="12" lg="12">
      <CCard class="shadow-sm" style=" border-radius: 0.1rem;">
        <CCardBody>
          <div class="row">
            <div class="col-md-12 col-12">
              <h4 class="font-weight-normal">
                {{ $t('information') }}
              </h4>
            </div>
          </div>
          <br />
          <CRow>
            <CCol sm="1" col="3" class="text-center"><img src="/img/icon/store.png" style="width: 32px; height:32px;"/>
            </CCol>
            <CCol sm="11" col="9">
              <select id="createCheckStockShopselect" class="custom-select text-dark" v-model="shopObjectId" disabled>
                <option :id="'createCheckStockShopOption' + shop.objectId" v-for="shop in shops" :key="shop.objectId" :value="shop.objectId">
                  {{ shop.shopName }} - {{ shop.branchName }}
                </option>
              </select>

              <div v-if="validateShop" class="text-danger">
                {{ validateShop }}
              </div>
            </CCol>
          </CRow>

          <CRow style="margin-top: 2%;">
            <CCol style="margin-top: 8px;" class="col-sm-2 col-12">
              <div class="text-sm-right text-left">
                {{ $t('checkStockName') }} :
              </div>
            </CCol>
            <CCol sm="10" md="10" col="12">
              <CInput id="createCheckStockNameInput" v-if="validatecheckStockname" class="text-dark" v-model="checkStockName" :is-valid="validator"
                :invalid-feedback="$t('validateCheckStock')" :placeholder="$t('checkStockName')">
              </CInput>
              <CInput id="createCheckStockNameDefaultInput" v-else class="text-dark" v-model="checkStockName" :placeholder="$t('checkStockName')">
              </CInput>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
      <CCard class="shadow-sm" style=" border-radius: 0.1rem;">
        <CCardBody>
          <div class="row">
            <div class="col-md-9 col-12">
              <h4 class="font-weight-dark">
                {{ $t('checkStockProduct') }}
              </h4>
              <p>
                {{ $t('checkStockDetail') }}
              </p>
            </div>
            <div class="col-md-3 col-12 text-sm-right text-left">
              <CButton id="createCheckStockAddProductButton" class="btn btn-outline-success btn-block" style="display: flex; align-items: center; justify-content: center;" @click="AddProductModal = true">
                <i class="fi fi-rr-plus" style="margin-right: 8px;"></i>  {{ $t('addProduct') }}
              </CButton>
            </div>
          </div>
          <CRow v-if="items && items.length != 0" class="mt-3">
            <CCol sm="12" lg="12">
              <table id="createCheckStockProductTable" style="white-space: nowrap;"
                class="table table-responsive table-bordered text-dark font-weight-normal">
                <thead>
                  <tr>
                    <th class="text-center text-dark font-weight-normal">#</th>
                    <th></th>
                    <th class="text-dark font-weight-normal">{{ $t('pluCode') }}</th>
                    <th class="text-dark font-weight-normal">{{ $t('productName') }}</th>
                    <th class="text-right text-dark font-weight-normal">{{ $t('checkCount') }}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in items" :key="index">
                    <p style="display:none">
                      {{ (item.orderIndex = index) }}
                    </p>
                    <td :id="'createCheckStockProductIndex' + index" class="text-center" style="width:1%">
                      <div style="margin-top:6px;">
                        {{ item.orderIndex + 1 }}
                      </div>
                    </td>
                    <td style="width: 2%;">
                      <div v-if="item.remoteImagePath" :style="{
    'background-image': `url('${item.remoteImagePath}'), url('${noImg}')`,
    'background-size': 'cover',
    'background-position': 'center center',
    'width': 'auto',
    'border-radius': '2px',
    'aspect-ratio': '1.3',
    'min-width': '50px',
  }"></div>
                      <div v-else style="border-radius: 2px" :style="{
    'aspect-ratio': '4 / 3',
    'min-width': '50px',
    backgroundColor: item.indexColor,
  }"></div>
                    </td>
                    <td style="width:15%">
                      <div class="text-dark font-weight-normal">
                        {{ item.productPLU.PLUCode }}
                      </div>
                    </td>
                    <td style="width:50%">
                      <div class="text-dark font-weight-normal">
                        {{ item.productPLU.name }}
                        ( {{ item.unit ? (item.unit.name || '-') : '-' }} / {{
    (item.productPLU.SKURatio || '1') }} )
                      </div>
                    </td>
                    <td :id="'createCheckStockProductQuantity' + index" style="width:5%" class="text-dark font-weight-normal">
                      <input :id="'createCheckStockProductQuantityInput' + index" type="number" min="0" class="form-control form-control-md text-right text-dark"
                        v-model.number="item.quantity" />
                    </td>
                    <td class="text-center" style="width:2%">
                      <CButton :id="'createCheckStockRemoveProductButton' + index" color="danger" size="sm" @click="removeProduct(item.orderIndex)">
                        <CIcon name="cil-trash"></CIcon>
                      </CButton>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p class="text-danger" v-if="warning != ''">{{ warning }}</p>
              <p v-if="duplicateItem != ''" class="text-danger">
                {{ duplicateItem }}
              </p>
            </CCol>
          </CRow>
          <CRow v-else class="mt-3">
            <CCol sm="12" lg="12">
              <CDataTable>
                <template #no-items-view>
                  <div class="text-center my-5">
                    <h4 style="color: #ced2d8;">
                      {{ $t('noItem') }}
                    </h4>
                  </div>
                </template>
              </CDataTable>
            </CCol>
          </CRow>
          <div v-if="validateItem" class="text-danger">
            {{ validateItem }}
          </div>
          <br />
        </CCardBody>

      </CCard>
    </CCol>

    <!-- Footer button -->
    <CCol>
      <div class="row justify-content-end" style="margin: 0">
        <CButton id="createCheckStockConfirmButton" class="btn btn-success col-lg-2 col-4 font-weight-normal" style="margin-right: 15px;" @click="handlePopup"
          v-if="items.length != 0">
          {{ $t('confirm') }}
        </CButton>
        <CButton id="createCheckStockDisabledConfirmButton" class="btn btn-success col-lg-2 col-4 font-weight-normal" style="margin-right: 15px;" v-else disabled>
          {{ $t('confirm') }}
        </CButton>
        <CButton id="createCheckStockCancelButton" class="btn col-lg-2 col-4 shadow-sm font-weight-normal"
          style="background-color: white;font-weight: bold;" @click="$router.go(-1)">
          {{ $t('cancel') }}
        </CButton>       
      </div>
      <br />
    </CCol>

    <!-- Confirm save modal -->
    <CRow>
      <CModal id="createCheckStockPopupModal" :show.sync="popupModal" color="success" :title="$t('confirmSaveDoc')" centered :footer="footer">
        <br />
        <h4 class="text-center">
          {{ $t('confirmCheckStockMsg') }}
        </h4>
        <br />

        <template #footer>
          <CRow class="justify-content-center col-md-12">
            <CCol col="6">
              <CButton id="createCheckStockPopupModalSaveButton" color="success" block v-if="loadingButton === true" v-on:click="formSubmit">
                {{ $t('save') }}
              </CButton>
              <CButton block color="success" v-else-if="loadingButton === false" disabled>
                <CSpinner color="white" size="sm" /> {{ $t('save') }}
              </CButton>
            </CCol>
            <CCol col="6">
              <CButton id="createCheckStockPopupModalCancelButton" block color="light" @click="popupModal = false">
                {{ $t('cancel') }}
              </CButton>
            </CCol>
          </CRow>
        </template>
      </CModal>
    </CRow>

    <CRow>
      <CModal id="createCheckStockAddProductModal" :show.sync="AddProductModal" centered :footer="footer" size="lg" style="max-width: 915px;">
        <template #header-wrapper class="header-modal">
          <header class="modal-header header-modal">
            <h4 class="modal-title font-weight-normal">
              {{ $t('selectdProduct') }}
            </h4>
            <button id="createCheckStockAddProductModalCloseButton" type="button" aria-label="Close" class="close" @click="AddProductModal = false">
              ×
            </button>
          </header>
        </template>

        <template #body-wrapper>
          <CCardBody>
            <CRow>
              <label class="col-sm-1 col-form-label text-left text-dark">
                {{ $t('search') }}
              </label>
              <CCol style="padding-right: 0;">
                <select id="createCheckStockSearchSelect" v-model="searchBy" class="custom-select no-border-radius-left">
                  <option id="createCheckStockSearchOptionPLUCode" value="PLUCode">{{ $t('pluCode') }}</option>
                  <option id="createCheckStockSearchOptionName" value="name">{{ $t('productName') }}</option>
                  <option id="createCheckStockSearchOptionCategory" value="category">{{ $t('category') }}</option>
                </select>
              </CCol>
              <CCol class="pl-0 col-6 ">
                <template v-if="searchBy === 'category'">
                  <select id="createCheckStockCategorySelect" v-model="selectedCategory" class="form-control text-dark no-border-radius-right" :placeholder="$t('selectCategory')">
                    <option selected value="">{{ $t('selectCategory') }}</option>
                    <option :id="'createCheckStockCategoryOption' + category.objectId" v-for="category in dataCategory" :key="category.objectId" :value="category.objectId">
                      {{ category.name }}
                    </option>
                  </select>
                </template>
                <template v-else>
                  <input id="createCheckStockSearchInput" class="form-control text-dark no-border-radius-right" v-model="keywordEntered" @input="searchProductPLU"
                    :placeholder="$t('searchProduct')" />
                </template>
              </CCol>
              <CCol class="p-0">
                <CButton id="createCheckStockSearchButton" block color="success" v-if="loadingButton === true" v-on:click="searchProductPLU">
                  {{ $t('search') }}
                </CButton>
              </CCol>
              <CCol>
                <CButton id="createCheckStockResetButton" class="btn btn-outline-secondary text-dark font-weight-normal"
                  style="font-weight: bold; width: inherit" v-on:click="reset">
                  {{ $t('reset') }}
                </CButton>
              </CCol>
            </CRow>
            <div style="max-height: 400px; overflow-y: auto;" class="mt-4">
              <CDataTable id="createCheckStockDataTable" pagination :fields="fields" :items="autoCompleteResult" hover border v-model="autoCompleteResult"
                clickableRows @row-clicked="onSelectedAutoCompleteEvent" class="text-dark">
                <template slot="selected-header">
                  <div class="custom-control custom-checkbox" style="padding-left: 2rem">
                    <input v-model="all" type="checkbox" @change="setAll()" class="custom-control-input success cursor"
                      id="checkbox" />
                    <label class="custom-control-label cursor" for="checkbox">
                    </label>
                  </div>
                </template>

                

                <template #selected="{ item, index }">
                  <td :id="'createCheckStockRowSelected' + index" style="vertical-align: middle;">
                    <div class="custom-control custom-checkbox" style="padding-left: 2rem">
                      <input type="checkbox" class="custom-control-input success cursor" v-model="item.selected"
                        @change="onSelectedAutoCompleteEvent(item)" :id="index" />
                      <label class="custom-control-label cursor" :for="index">
                      </label>
                    </div>
                  </td>
                </template>

                <template #name="{ item, index }">
                  <td style="vertical-align: middle; border-left: hidden" :for="index">
                    <img v-if="item.remoteImagePath" :src="item.remoteImagePath"
                      style="border-radius: 2px; aspect-ratio: 4 / 3" class="img-fluid" @error="noImgUrl" />
                    <div v-else style="border-radius: 2px" :style="{
    'aspect-ratio': '4 / 3',
    backgroundColor: item.indexColor,
  }"></div>
                  </td>
                </template>

                <template #nameproduct="{ item }">
                  <td class="text-dark" style="vertical-align: middle;border-left: hidden;">
                    {{ item.name }}
                  </td>
                </template>
                <template #categoryName="{ item }">
                  <td class="text-dark font-weight-normal" style="vertical-align: middle;border-left: hidden;">
                    {{ item.categoryName }}
                  </td>
                </template>
                <template #pluCode="{ item }">
                  <td class="text-dark" style="vertical-align: middle;border-left: hidden;">
                    {{ item.PLUCode }}
                  </td>
                </template>

                <template #price="{ item, index }">
                  <td :for="index" style="vertical-align: middle; border-left: hidden " v-if="item.ProductSKU.stdCost === '' ||
    item.ProductSKU.stdCost === undefined
    ">
                    ฿ 0
                  </td>
                  <td :for="index" style="vertical-align: middle;border-left: hidden " v-else>
                    ฿ {{ item.ProductSKU.stdCost }}
                  </td>
                </template>

                <template #onhanQTY="{ item, index }">
                  <td class="text-right text-dark" :for="index" style="vertical-align: middle; border-left: hidden "
                    v-if="item.ProductPLUStock.onhandQty === '' ||
    item.ProductPLUStock.onhandQty === undefined
    ">
                    0
                  </td>
                  <td class="text-right text-dark" :for="index" style="vertical-align: middle; border-left: hidden "
                    v-else>
                    <div v-if="floatValue(item.ProductPLUStock.onhandQty) >= 0" class="text-dark">
                      {{ item.ProductPLUStock.onhandQty }}
                    </div>
                    <div v-else class="text-danger">
                      {{ item.ProductPLUStock.onhandQty }}
                    </div>
                  </td>
                </template>

                <template #no-items-view>
                  <div class="text-center my-5">
                    <h4 style="color: #ced2d8;">
                      {{ $t('noItem') }}
                    </h4>
                  </div>
                </template>
              </CDataTable>
            </div>
          </CCardBody>
        </template>

        <template #footer-wrapper>
          <CRow class="justify-content-end col-md-12">
            <CCol col="12" lg="6" class="row">
              <div class="col-6">
                <CButton id="createCheckStockConfirmButton" block color="success" v-if="loadingButton === true" v-on:click="onCheckboxChange">
                  {{ $t('confirm') }}
                </CButton>
                <CButton block color="success" v-else-if="loadingButton === false" disabled>
                  <CSpinner color="white" size="sm" /> {{ $t('confirm') }}
                </CButton>
              </div>
              <div class="col-6">
                <CButton id="createCheckStockCancelButton" class="btn btn-outline-secondary text-dark font-weight-normal" style="width: inherit"
                  @click="AddProductModal = false">
                  {{ $t('cancel') }}
                </CButton>
              </div>
            </CCol>
          </CRow>
          <br />
        </template>
      </CModal>
    </CRow>
  </div>
  <div v-else>
    <access-data></access-data>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from '@/services/local'
import util from '@/util/util'
import permis from '@/util/permission'
import pos from '@/services/local'

export default {
  data() {
    return {
      validatecheckStockname: '',
      checkStockName: '',
      data: [],
      itemList: [],
      items: [],
      shop: this.$store.getters.shopObjectId,
      alert: false,
      searchBy: 'name',
      label: 'name',
      keyword: '',
      placeHolderInputText: 'พิมพ์ชื่อสินค้าที่ต้องการค้นหา .​.​.',
      autoCompleteResult: [],
      autoCompleteProgress: false,
      autoCompleteText: 'name',
      autoCompleteText2: 'PLUCode',
      autoCompleteText3: 'unitName',
      autoCompleteFieldId: 'alpha3Code',
      validateShop: '',
      validateItem: '',
      popupModal: false,
      footer: '',
      loadingButton: true,
      warning: '',
      duplicateItem: '',
      AddProductModal: false,
      keywordEntered: '',
      selectedItems: [],
      all: false,
      note: '',
      checkStockItem: [],
      dataCategory: [],
      selectedCategory: '',
      selectedProducts: [],
    }
  },
  computed: {
    ...mapGetters(['shops', 'users', 'date', 'access']),
    isPermission() {
      return permis.findPermissionRead('inventory', '/inventory/adjust-stock')
    },
    isEdit() {
      return permis.findPermissionEdit('inventory', '/inventory/adjust-stock')
    },
    uid() {
      return `${localStorage.getItem('shopsUid')}`
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShop', newValue)
      },
    },
    noImg() {
      return process.env.VUE_APP_NOIMAGE
    },
    fields() {
      return [
        {
          key: 'selected',
          label: this.$i18n.t('selectd'),
          _style: 'width:5%; vertical-align: middle;',
          _classes: 'text-left text-dark font-weight-normal',
        },
        {
          key: 'name',
          label: '',
          _style: 'width:7%; vertical-align: middle;border-left: hidden',
          _classes: 'text-left text-dark font-weight-normal',
        },
        {
          key: 'nameproduct',
          label: this.$i18n.t('product'),
          _style: 'width:30%; vertical-align: middle;border-left: hidden',
          _classes: 'text-left text-dark font-weight-normal',
        },
        {
          key: 'pluCode',
          label: this.$i18n.t('pluCode'),
          _style: 'width:25%; vertical-align: middle;border-left: hidden',
          _classes: 'text-left text-dark font-weight-normal',
        },
        {
          key: 'categoryName',
          label: this.$i18n.t('category'),
          _style: 'width:23%; vertical-align: middle;',
          _classes: 'text-left font-weight-normal text-dark',
        },
        {
          key: 'onhanQTY',
          label: this.$i18n.t('onhandQty'),
          _classes: 'text-right font-weight-normal text-dark',
          _style: 'width:30%; text-dark vertical-align: middle;border-left: hidden',
        },
      ]
    },
  },
  created() {
    this.searchProductPLU()
    this.shop = this.shops.find((i) => i.objectId === this.shop)
    this.getCategory()
  },
  methods: {
    getCategory() {
      const uid = `${localStorage.getItem('shopsUid')}`;
      const shopObjectId = this.shopObjectId;
      let params = {
        page: 1,
        limit: 1000,
      };
      const headers = {
          shopObjectId: shopObjectId,
      }

      pos
        .get('/api/v1.0/shop/' + uid + '/Category/data/' + shopObjectId, {
          params,headers
        })
        .then((res) => {
          let detail = []
          let data = res.data.data
          for (let i = 0; i < data.length; i++) {
            if (data[i].enabled === true) {
              detail.push({
                id: data[i].id,
                name: data[i].name || '-',
                objectId: data[i].objectId,
              })
            }
          }
          this.dataCategory = detail
          })
        .catch((error) => {
          console.error("Error fetching categories:", error);
        });
    },
    validator(val) {
      return val ? val.length >= 1 : false
    },
    noImgUrl(event) {
      event.target.src = process.env.VUE_APP_NOIMAGE
    },
    setAll() {
      let select = this.selectedItems
      let data = this.autoCompleteResult
      // this.selectedItems = []
      if (this.all) {
        data.forEach(function (item) {
          item.selected = true
          select.push(item)
        })
      } else {
        data.forEach(function (item) {
          item.selected = false
          let i = select.findIndex((x) => x.objectId === item.objectId)
          if (i != -1) {
            select.splice(i, 1)
          }
        })
      }
      this.selectedItems = select
      this.autoCompleteResult = data
    },
    reset() {
      this.selectedItems = []
      this.all = false
      this.keywordEntered = ''
      this.searchBy = 'name'
      this.selectedCategory = ''
      this.searchProductPLU()
    },
    handlePopup() {
      if (this.shopObjectId === '') {
        this.popupModal = false
        this.validateShop = this.$i18n.t('selectShopPlease')
      } else {
        this.validateShop = ''
      }

      if (this.items.length === 0) {
        this.popupModal = false
        this.validateItem = this.$i18n.t('selectProductPlease')
      } else {
        this.validateItem = ''
      }

      if (this.items.length != 0 && this.shopObjectId != '') {
        this.popupModal = true
      }
      if (this.checkStockName === '') {
        this.validatecheckStockname = 'กรุณากรอกชื่อเอกสารตรวจนับสินค้า'
        this.popupModal = false
      } else {
        this.validatecheckStockname = ''
      }
    },
    removeProduct(orderIndex) {
      let items = this.items
      let newItems = items.filter((item) => item.orderIndex != orderIndex)
      this.items = newItems
      this.selectedItems.forEach((selectedItem) => {
        if (!newItems.some((item) => item.productPLUId === selectedItem.id)) {
          selectedItem.selected = false;
        }
      });
      this.selectedItems = this.selectedItems.filter((selectedItem) => selectedItem.selected);
    },
    onCheckboxChange() {
      let data = this.selectedItems
      let items = this.items
      let uid = this.uid
      let shop = this.shop
      let select = []
      // console.log(this.);
      data.forEach(function (item, index) {
        let stdCost = 0
        let previousOnhand = 0
        let SKURatio = '-'
        if (item.selected) {
          select.push(item)
          if (
            item.ProductSKU.stdCost === undefined ||
            item.ProductSKU.stdCost === null
          ) {
            stdCost = 0
          } else {
            stdCost = item.ProductSKU.stdCost
          }

          if (item.SKURatio == undefined) {
            SKURatio = '-'
          } else {
            SKURatio = item.SKURatio
          }
          if (
            item.ProductPLUStock.onhandQty === undefined ||
            item.ProductPLUStock.onhandQty === null
          ) {
            previousOnhand = 0
          } else {
            previousOnhand = item.ProductPLUStock.onhandQty
          }
          if (items.length > 0) {
            let i = items.findIndex(
              (x) => x.productPLU.objectId === item.objectId
            )
            if (i == -1) {
              items.push({
                uid: uid,
                orderIndex: index,
                productPLU: {
                  id: item.id,
                  objectId: item.objectId,
                  PLUCode: item.PLUCode,
                  name: item.name,
                  unit: item.unit,
                  SKURatio: item.SKURatio
                },
                productPLUId: item.id,
                productSKU: {
                  id: item.ProductSKU.id,
                  objectId: item.ProductSKU.objectId,
                  SKUName: item.ProductSKU.SKUName,
                  SKUPrice: item.ProductSKU.SKUPrice,
                  indexColor: item.ProductSKU.indexColor,
                  skuType: item.ProductSKU.skuType,
                  vatType: item.ProductSKU.vatType
                },
                productSKUId: item.ProductSKU.id,
                previousOnhand: previousOnhand,
                shop: {
                  id: shop.id,
                  objectId: shop.objectId,
                  shopName: shop.shopName,
                  branchName: shop.branchName,
                },
                shopId: shop.id,
                quantity: 0,
                unit: {
                  name: item.unitName,
                },
                SKURatio: item.SKURatio,
                remoteImagePath: item.remoteImagePath,
                indexColor: item.indexColor,
              })
            }
          } else {
            items.push({
              uid: uid,
              orderIndex: index,
              productPLU: {
                id: item.id,
                objectId: item.objectId,
                PLUCode: item.PLUCode,
                name: item.name,
                unit: item.unit,
                SKURatio: item.SKURatio
              },
              productPLUId: item.id,
              productSKU: {
                id: item.ProductSKU.id,
                objectId: item.ProductSKU.objectId,
                SKUName: item.ProductSKU.SKUName,
                SKUPrice: item.ProductSKU.SKUPrice,
                indexColor: item.ProductSKU.indexColor,
                skuType: item.ProductSKU.skuType,
                vatType: item.ProductSKU.vatType
              },
              productSKUId: item.ProductSKU.id,
              previousOnhand: previousOnhand,
              shop: {
                id: shop.id,
                objectId: shop.objectId,
                shopName: shop.shopName,
                branchName: shop.branchName,
              },
              ProductPLUStock: item.ProductPLUStock,
              shopId: shop.id,
              quantity: 0,
              unit: {
                name: item.unitName,
              },
              SKURatio: item.SKURatio,
              remoteImagePath: item.remoteImagePath,
              indexColor: item.indexColor,
            })
          }
        } else {
          let index = items.findIndex(
            (x) => x.productPLU.objectId === item.objectId
          )
          if (index != -1) {
            items.splice(index, 1)
          }
        }
      })

      this.items = items
      this.selectedItems = select
      this.AddProductModal = false
      this.all = false
    },
    onSelectedAutoCompleteEvent(item) {
      item.selected = !item.selected
      if (item.selected) {
        this.selectedItems.push(item)
      } else {
        let i = this.selectedItems.findIndex(
          (x) => x.objectId === item.objectId
        )
        if (i != -1) {
          this.selectedItems.splice(i, 1)
        }
      }
      let countItem = this.autoCompleteResult.length
      let coutselect = 0
      this.autoCompleteResult.forEach(function (item) {
        if (item.selected) {
          coutselect = coutselect + 1
        }
      })
      if (countItem === coutselect) {
        this.all = true
      } else {
        this.all = false
      }
    },
    searchProductPLU() {
      if (this.shopObjectId === '') {
        this.validateShop = this.$i18n.t('selectShopPlease')
      } else {
        this.validateShop = ''
      }

      const uid = `${localStorage.getItem('shopsUid')}`
      let params = {}
      if (this.searchBy === 'category' && this.selectedCategory) {
        params = {
          shopObjectId: this.$store.getters.shopObjectId,
          categoryObjectId: this.selectedCategory,
        }
      } else if (this.keywordEntered != '') {
        params = {
          shopObjectId: this.$store.getters.shopObjectId,
          searchBy: this.searchBy,
          keyword: this.keywordEntered,
        }
      } else {
        params = {
          shopObjectId: this.$store.getters.shopObjectId,
          searchBy: this.searchBy,
        }
      }
      this.label = this.searchBy
      let selectedItems = this.selectedItems
      // if (keywordEntered.length > 0) {
      const headers = { shopObjectId: this.$store.getters.shopObjectId }

      axios({
        url: '/api/v1.0/' + uid + '/getproductpluwithstock',
        params: params,
        headers: headers,
        method: 'GET',
      })
        .then((response) => {
          this.alert = false
          let newData = []
          response.data.data.forEach(function (item) {
            if (selectedItems.length > 0) {
              let i = selectedItems.findIndex(
                (x) => x.objectId === item.objectId
              )
              if (i != -1) {
                item.selected = true
              } else {
                item.selected = false
              }
            } else {
              item.selected = false
            }
            if (item.unit == undefined) {
              item.unitName = '-'
            } else {
              if (item.unit.name == undefined) {
                item.unitName = '-'
              } else {
                item.unitName = item.unit.name
              }
            }
            const category = item.category || {}
            item.categoryName = category.name || '-'
            item.indexColor = util.generateColor(item.ProductSKU.indexColor)
            newData.push(item)
          })
          this.autoCompleteResult = newData
        })
        .catch((e) => {
          this.alert = true
          console.log(e)
        })
      // }
    },
    formSubmit(e) {
      this.loadingButton = false
      e.preventDefault()
      const uid = this.uid
      const plan = this.users.currentPlan

      const date = new Date();
      const year = date.getFullYear().toString().substr(-2);
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const day = ('0' + date.getDate()).slice(-2);
      const YYMMDD = year + month + day;
      const randomString = Math.random().toString(36).substring(2, 10).toUpperCase();
      const checkStockNo = `${YYMMDD}-${randomString}`;
      function generateUniqueID() {
        const characters = 'abcdef0123456789';
        let id = '';
        for (let i = 0; i < 24; i++) {
          id += characters[Math.floor(Math.random() * characters.length)];
        }
        return id;
      }

      const created_by = {
        username: 'Admin',
        id: 'User001',
        objectId: generateUniqueID()
      }
      const updated_by = {
        username: 'Admin',
        id: 'User001',
        objectId: generateUniqueID()
      }
      const objectId = generateUniqueID()
      const id = generateUniqueID()
      const dataItem = []
      this.items.forEach(item => {
        dataItem.push({
          objectId: generateUniqueID(),
          id: generateUniqueID(),
          created_by_id: 'User001',
          updated_by_id: 'User001',
          checkStockDocumentId: id,
          checkStockDocument: {
            objectId: objectId,
            id: id,
            checkStockNo: checkStockNo,
          },
          ...item,
          created_by: created_by,
          updated_by: updated_by
        })
      })

      this.shop = this.shops.find((i) => i.objectId === this.shopObjectId)
      let shop = {
        objectId: this.shopObjectId,
        id: this.shop.id,
        branchName: this.shop.branchName
      }
      let data = {
        objectId: objectId,
        id: id,
        checkStockNo: checkStockNo,
        created_at: new Date,
        created_by: created_by,
        created_by_id: 'User001',
        updated_at: new Date,
        note: this.checkStockName,
        items: dataItem,
        shop: shop,
        shopId: this.shop.id,
        uid: uid,
        updated_at: new Date(),
        updated_by: updated_by,
        updated_by_id: 'User001'
      };
      const headers = { shopObjectId: this.$store.getters.shopObjectId }

      if (this.shop.id != undefined && dataItem.length != 0) {
        axios({
          method: 'post',
          url: '/api/v2.2/' + uid + '/CheckStockDocument',
          params: { shopObjectId: this.shopObjectId, plan: plan },
          data: data,
          headers: headers,
        })
          .then(() => {
            setTimeout(() => {
              this.isSuccess = true
              
              this.$router.push('/inventory/check-stock')
            }, 5000)
          })
          .catch((error) => {
            console.log(error)
          })
      }
    },
    floatValue(value) {
      return parseFloat(value)
    },
  },
}
</script>

<style>
.no-border-radius-left {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
}

.no-border-radius-right {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.modal-body {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}

/* Hidden arrow  */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style src="../../../assets/styles/inventory.css" scoped></style>